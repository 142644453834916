import axios from "axios";
import StorageUtil from "@/utils/StorageUtil";

const http = axios.create({
    // baseURL: "http://139.9.61.174:888/",
    // baseURL: "http://1.12.238.105:888/",
    // baseURL: "http://127.0.0.1:8880/small_admin",
    baseURL: "http://42.194.198.254:888/small_admin",
    // baseURL: "http://127.0.0.1:8884",               //药品模块
    headers: {'Content-Type': 'multipart/form-data'}
});

// 添加请求拦截器
http.interceptors.request.use(
    function (config) {
        config.headers['token'] = StorageUtil.Local.get("token");
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

http.interceptors.response.use(
    function (response) {
        console.log(response);
        if (response.data.msg == "尚未检测到用户的登录令牌") {
            window.location.href = "/#/login";
        }
        return response;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export default http;