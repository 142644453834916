import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/main',
    name: 'main',
    component: () => import('../views/MainView.vue'),
    children: [
      {
        path: 'order',
        name: 'order',
        component: () => import('../views/order/OrderView.vue'),
      },
      {
        path: 'ad',
        name: 'ad',
        component: () => import('../views/ad/AdView.vue'),
      },
      {
        path: 'medicine',
        name: 'medicine',
        component: () => import('../views/medicine/MedicineView.vue'),
      },
      {
        path: 'add-medicine',
        name: 'addMedicine',
        component: () => import('../views/medicine/AddUpdateView.vue'),
      },
      {
        path: 'category',
        name: 'category',
        component: () => import('../views/category/CategoryView.vue'),
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
