<template>
    <router-view>

    </router-view>

</template>

<style>
body {
  overflow: hidden;
  background-color: #F6F8F9;
  margin: 0px;
}
</style>
