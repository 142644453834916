import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'


import 'element-plus/dist/index.css'

import router from './router'
import store from './store'

import '@/assets/main.css'

const app = createApp(App)


app.use(store)
app.use(router)

app.use(ElementPlus)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

import http from '@/utils/request'
app.config.globalProperties.$http = http;
app.config.globalProperties.$post = http.post;
app.config.globalProperties.$serverBaseUrl = "http://42.194.198.254:888/pic/";

app.mount('#app')
