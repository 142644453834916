import { createStore } from 'vuex'

export default createStore({
  state: {
    // isBack: false,
    title: "订单详情",
  },
  getters: {
  },
  mutations: {
    setTitle(state, title) {
      state.title = title
    }
  },
  actions: {
  },
  modules: {
  }
})
